import React from "react"
import GoBackArrow from "../../../components/GoBackArrow"

export default function main() {
  return (
    <div className="faq-page">
      <GoBackArrow />
      <div className="page-wrapper">
        <h1 className="description-header">FAQ</h1>
        <div className="faq">
          <div className="question">
            Do I need any special equipment to be set up for streaming?
          </div>
          <div className="answer">
            In order to be set up, you will need a laptop, a DAW (Logic,
            Ableton, Rekordbox or similar), an audio interface/sound card. You
            will be asked to download 3 pieces of software ahead of your
            soundcheck that are all free and have been tested by the Smiley
            Sound team.
          </div>
          <div className="question">Will this affect my recording set up?</div>
          <div className="answer">
            {" "}
            Not at all. The setup that we provide can be used with your setup or
            be swapped over very easily.
          </div>
          <div className="question">
            Can I stream to more than one platform at a time?
          </div>
          <div className="answer">
            Yes! However if you want to stream to Instagram Live, then you will
            only be able to stream to that platform. We are working on a way to
            include Instagram in a multi-platform stream and will keep you
            posted.
          </div>
          <div className="question">
            Can I look at the comments on my phone while streaming to Instagram?
          </div>
          <div className="answer">
            You will be unable to view the comments from your Instagram account
            on your phone while you are streaming from your computer. In order
            to access the comments in real time you will need to view the stream
            from a guest account.
          </div>
          <div className="question">
            Does this setup work for Mac and Windows?
          </div>
          <div className="answer">
            At the moment we don’t have a setup available for Windows users but
            we are currently figuring out a solution.
          </div>
        </div>
        <h2 className="block-separator" style={{ marginTop: "55px" }}>
          DOWNLOAD CARE PACKAGE:
        </h2>
        <div className="clients-list">
          <p>
            <a href="https://obsproject.com/download" target="_blank">
              Streaming software
            </a>
            &nbsp;//
          </p>
          <p>
            <a href="https://yellowduck.tv" target="_blank">
              <span className="empty-space-1st">&nbsp;</span>Instagram live
              client
            </a>
            &nbsp;//
          </p>
          <p>
            <a href="https://existential.audio/blackhole/" target="_blank">
              <span className="empty-space-2nd">&nbsp;</span>Virtual audio
              driver
            </a>
          </p>
        </div>
      </div>
      <h2 className="block-separator" style={{ marginTop: "5px" }}>
        SUPPORT US:
      </h2>
      <form
        target="paypal"
        action="https://www.paypal.com/cgi-bin/webscr"
        method="post"
      >
        <input type="hidden" name="cmd" value="_s-xclick" />
        <input type="hidden" name="hosted_button_id" value="F5QEMCNZKXEKU" />
        <input
          type="image"
          src="https://www.paypalobjects.com/en_GB/i/btn/btn_donate_SM.gif"
          border="0"
          name="submit"
          title="PayPal - The safer, easier way to pay online!"
          alt="Donate with PayPal button"
        />
        <img
          alt=""
          border="0"
          src="https://www.paypal.com/en_GB/i/scr/pixel.gif"
          width="1"
          height="1"
        />
      </form>
    </div>
  )
}
